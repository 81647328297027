import { Helmet } from "react-helmet"
import { Formik, Form, ErrorMessage } from "formik";
import { loginSchema } from "../utils/validators";
import { supabase } from "../utils/supabase";
import { useAuth } from "../utils/auth/AuthContext";
import toast from 'react-hot-toast';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../utils/errorMessages";
import { PiEyeSlash, PiEye } from "react-icons/pi"
import Logo from "../assets/images/logo.png"

function Login() {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [showPassword, togglePassword] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user])

  const handleLogin = async (values, resetForm) => {
    setLoading(true)

    if(values.email === "admin@mlegacytv.com"){
      try{
        const { data, error } = await supabase.auth.signInWithPassword({
          email: values.email,
          password: values.password
        });
  
        if(data && data.user){
          navigate("/");
          toast.success("Successfully logged in", { position: "top-right" });
        } else if (error) {
          toast.error(`${errorMessages[error] ? errorMessages[error] : error}`, { position: "top-right" });
        }
        
      } catch(error) {
        toast.error(`${error.message}`, { position: "top-right" });
      }
    } else {
      toast.error("This account is not allowed to access.", { position: "top-right" });
      setLoading(false)
      resetForm({ email: "", password: "" });
      return;
    }

    setLoading(false)
    resetForm({ email: "", password: "" });
  }

  return (
    <div className='w-screen h-screen flex justify-center items-center overflow-hidden'>
      <Helmet>
        <title>Welcome - Mlegacytv</title>
      </Helmet>
      <main className='rounded-md p-8'>
        <img src={Logo} alt="logo" className='h-16 mx-auto mb-5' />

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={loginSchema}
          onSubmit={(values, { resetForm }) => {handleLogin(values, resetForm)}}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <div className='flex flex-col gap-1 mb-3'>
                <label htmlFor="email">Email</label>
                <input type="email" name="" id="" className='bg-transparent border-[1px] border border-gray-400 rounded-[4px] px-3 py-2 w-[350px]' placeholder='Enter email' onChange={handleChange("email")}
                  onBlur={handleBlur("email")} value={values.email} />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className='flex flex-col gap-1 mb-3'>
                <label htmlFor="password">Password</label>
                <div className="relative">
                  <input type={showPassword ? "text" : "password"} name="" id="" className='bg-transparent border-[1px] border border-gray-400 rounded-[4px] px-3 py-2 w-[350px]' placeholder='Enter password' onChange={handleChange("password")}
                    onBlur={handleBlur("password")} value={values.password} />
                  <span
                    className="absolute right-2 top-[25%] cursor-pointer"
                    onClick={() => togglePassword((prev) => !prev)}
                  >
                    {showPassword ? <PiEyeSlash size={18} /> : <PiEye size={18} />}
                  </span>
                </div>
               
                <ErrorMessage name="password">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <input type="submit" className="text-white font-medium bg-sky-500 w-full mt-3 px-3 py-2 rounded-[4px] cursor-pointer w-[350px]" value={loading ? "Loading..." : "Login"}/>
            </Form>
          )}
        </Formik>
      </main>

    </div>
  )
}

export default Login