import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { BiMovie } from "react-icons/bi";
import { RiMovie2Line } from "react-icons/ri";
import { HiOutlineUsers } from "react-icons/hi";
import { TbCategory } from "react-icons/tb";
import { MdOutlineSettingsVoice } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import { LiaShirtsinbulk } from "react-icons/lia";

function MobileSideMenu({setShowSidebar}) {
  const [showIndex, setShowIndex] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='w-screen h-screen bg-[#0a0a0a]/90 fixed z-50 top-0 right-0 left-0 bottom-0 overflow-hidden'>
        <main className='flex flex-col gap-10 w-10/12 md:w-6/12 lg:w-4/12 bg-[#1C2340] h-screen px-5 py-8'>
          <div className='rounded-full bg-[#404757] w-[32px] h-[32px] flex justify-center items-center p-2 cursor-pointer' onClick={() => setShowSidebar(false)}>
              <FaArrowLeft  size={16}/>
          </div>

          <ul className="mb-10 mt-2">
            <Link to="/">
                <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/' ? 'bg-[#404757]' : ''}`} onClick={() => setShowSidebar(false)}>
                    <AiOutlineHome className="inline-block mr-2" />
                    Dashboard
                </li>
            </Link>

            <p className="pt-2 text-xs font-light text-gray-400 mt-2 mb-1">CONTENTS</p>

            <li className={`text-white px-3 py-2 cursor-pointer mb-1 flex flex-col ${['/add-movies', '/all-movies'].includes(location.pathname) ? 'bg-[#404757]' : ''}`} onClick={() => {
                navigate("/add-movies")
                setShowIndex("movies")
                }}>
                <div className="flex justify-between items-center w-full">
                    <div className="flex gap1 items-center">
                        <BiMovie className="inline-block mr-2" /> Movies 
                    </div>
                    {showIndex === "movies" ? <IoIosArrowUp /> : <IoIosArrowDown />} </div>
                </li>
                {showIndex === "movies" &&
                <ul className="bg-[#1C2340] px-5 py-2 text-gray-400 text-sm">
                    <Link to="/add-movies"><li  className={`pb-1 ${location.pathname === '/add-movies' ? 'text-white font-medium' : ''}`} onClick={() => setShowSidebar(false)}>Add Movies</li></Link>
                    <Link to="/all-movies"><li  className={`pb-1 ${location.pathname === '/all-movies' ? 'text-white font-medium' : ''}`} onClick={() => setShowSidebar(false)}>All Movies</li></Link>
                </ul>
                }
            
            <li className={`text-white px-3 py-2 cursor-pointer mb-1 flex flex-col ${['/add-series', '/all-series'].includes(location.pathname) ? 'bg-[#404757]' : ''}`} onClick={() => {
          navigate("/add-series")
          setShowIndex("series")
        }}>
          <div className="flex justify-between items-center w-full">
            <div className="flex gap1 items-center">
                <RiMovie2Line className="inline-block mr-2" /> TV Series 
            </div>
            {showIndex === "series" ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </li>
        {showIndex === "series" &&
          <ul className="bg-[#1C2340] px-5 py-2 text-gray-400 text-sm">
            <Link to="/add-series"><li className={`pb-1 ${location.pathname === '/add-series' ? 'text-white font-medium' : ''}`} onClick={() => setShowSidebar(false)}>Add Series</li></Link>
            <Link to="/all-series"><li className={`pb-1 ${location.pathname === '/all-series' ? 'text-white font-medium' : ''}`} onClick={() => setShowSidebar(false)}>All Series</li></Link>
          </ul>
        }

            <p className="pt-2 text-xs font-light text-gray-400 mt-2">SPECIAL</p>
            <Link to="/users">
                <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/users' ? 'bg-[#404757]' : ''}`} onClick={() => setShowSidebar(false)}>
                    <HiOutlineUsers className="inline-block mr-2" />
                    Users
                </li>
            </Link>
            <Link to="/genres">
                <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/genres' ? 'bg-[#404757]' : ''}`} onClick={() => setShowSidebar(false)}>
                    <TbCategory className="inline-block mr-2" />
                    Genres
                </li>
            </Link>
            <Link to="/vjs">
                <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/vjs' ? 'bg-[#404757]' : ''}`} onClick={() => setShowSidebar(false)}>
                    <MdOutlineSettingsVoice className="inline-block mr-2" />
                    VJs
                </li>
            </Link>

            <p className="pt-2 text-xs font-light text-gray-400 mt-2 mb-1">IMPORT</p>
            <Link to="/subscriptions">
                <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/subscriptions' ? 'bg-[#404757]' : ''}`} onClick={() => setShowSidebar(false)}>
                    <HiOutlineUsers className="inline-block mr-2" />
                    Subscriptions
                </li>
            </Link>
            <Link to="/bulk">
                <li className={`text-white px-3 py-2 cursor-pointer flex items-center ${location.pathname === '/bulk' ? 'bg-[#404757]' : ''}`} onClick={() => setShowSidebar(false)}>
                    <LiaShirtsinbulk className="inline-block mr-2" />
                    Bulk
                </li>
            </Link>
          </ul>
          </main>
        </div>
  )
}

export default MobileSideMenu