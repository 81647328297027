import { supabase } from "../utils/supabase"

function DeleteMoviesModal({setShowModal, movie}) {

    const handleSubmit = async () => {
        const { error } = await supabase
        .from('movies')
        .delete()
        .eq('id', movie.id)

        if (error) {
            alert(error.message)
        }

        setShowModal(false)
    }

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#1C2340] rounded-md w-8/12 md:w-6/12 lg:w-4/12 overflow-y-scroll overflow-x-hidden relative modalContain px-4 py-6"
      >
            <h3 className="my-5">Delete {movie.title}</h3>

            <div className="flex justify-between">
                  <button type="submit" className='px-3 py-1 text-white font-medium bg-sky-500 text-sm rounded-md' onClick={() => handleSubmit()}>Delete</button>
                  <button type="button" className='px-3 py-1 text-white font-medium border border-[#00acc1] text-[#00acc1] text-sm rounded-md' onClick={() => setShowModal(false)}>Cancel</button>
              </div>
        
      </div>
    </div>
  )
}

export default DeleteMoviesModal