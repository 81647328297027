import { useEffect, useState } from "react"
import { supabase } from "../utils/supabase"
import NotificationModal from "../components/NotificationModal"
import toast from 'react-hot-toast';
import EditNotificationModal from "../components/EditNotitificationModal";

function Notifications() {
  const [subscriptions, setSubscriptions] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getSubscriptions()

    supabase
        .channel("public:notifications")
        .on("postgres_changes", { event: "*", schema: "public", table: "notifications" }, (payload) => {
            getSubscriptions()
        })
        .subscribe();
  }, [])

  const getSubscriptions = async () => {
    const { data, error } = await supabase.from('notifications').select(`*`)
    
    if(data){
      setSubscriptions(data)
    }
  }
  const deleteNotification = async (id) => {
    const { error } = await supabase.from('notifications').delete().eq('id',id)

    if(error) {
      toast.error('Failed to delete Notification' , {position: 'top-right'})

    } else {
      toast.success('Notification deleted Successfully', {position: 'top-right'})
    // getSubscriptions()
    }
  }

  const handleSend = async (title, overview) => {
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      
      const res = await fetch(`https://storage.mlegacytv.com/api/create_notification_with_image/${title}/${"https://dashboard.mlegacytv.com/static/media/logo.7375c066fa48c13b86ea.png".replace(/\//g, '$')}/${overview.substring(0, 70)}...`, requestOptions);
  
  
      if(res.status === 200){
        toast.success('Push notification sent successfully', { position: "top-right" })
      }
  
      setSelectedIndex(null)
  }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
          <h1>Notifications</h1>
          <p className='text-gray-400'>Manage Notifications</p>
        </div>

        <button className='px-3 py-2 text-white font-medium bg-sky-500 text-sm rounded-md' onClick={() => setShowModal(true)}>Add Notification</button>
      </div>
      {showModal && <NotificationModal setShowModal={setShowModal} />}

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340]">
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none">z
            <thead>
              <tr className="border-b-[1px] border-gray-600">
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Title</th>
                <th className="py-2 text-left pl-3">Message</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions && subscriptions.map((sub, index) => (
                <tr key={sub.id} className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                  <td className="py-2 text-left pl-3">{index + 1}</td>
                  <td className="py-2 text-left pl-3">{sub.title}</td>z
                  <td className="py-2 text-left pl-3">{sub.message}</td>
                  <td className="py-2 text-left pl-3">
                    <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md mr-2' onClick={() => handleSend(sub.title,sub.message)}>Send</button>
                    <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md' onClick={() => setSelectedIndex(sub)}>Edit</button>
                    <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md' onClick={() => deleteNotification(sub.id)}>Delete</button>
                  </td>
                  {selectedIndex && selectedIndex.id === sub.id && (
                    <EditNotificationModal sub={sub} setShowAddSeason={setSelectedIndex} />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Notifications
