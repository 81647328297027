import { Formik, Form } from "formik"
import { supabase } from "../utils/supabase"

function EditSeasonModal({ setShowAddSeason, season}) {

    const handleSubmit = async (values, resetForm) => {
        const { error } = await supabase
        .from('seasons')
        .update(values)
        .eq('id', season.id)

        if (error) {
            alert(error.message)
        }
        resetForm({ 
            title: "",
            order: "",
            episode_count: "",
            serie_id: "",
            published: false,
            latest: false,
            trending: false,
            popular: false,
            recommend: false,
         })

        setShowAddSeason(false)
    }

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#1C2340] rounded-md w-11/12 md:w-9/12 lg:w-6/12 overflow-y-scroll overflow-x-hidden relative modalContain px-4 py-6"
      >
        <h2 className="my-5">Edit Season</h2>
        <Formik
            initialValues={{
                title: season.title,
                order: season.order,
                episode_count: season.episode_count,
                serie_id: season.serie_id,
                published: season.published,
                latest: season.latest,
                trending: season.trending,
                popular: season.popular,
                recommend: season.recommend,
              }}
              enableReinitialize
              // validationSchema={loginSchema}
              onSubmit={(values, { resetForm }) => {handleSubmit(values, resetForm)}}
        >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="title">Season Name</label>
                <input type="text" name="title" id="title" placeholder='Enter title' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("title")}
                onBlur={handleBlur("title")} value={values.title} />
            </div>

            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="order">Order</label>
                <input type="text" name="order" id="order" placeholder='Enter order' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("order")}
                onBlur={handleBlur("order")} value={values.order} />
            </div>

            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="episode_count">Number of Episodes</label>
                <input type="text" name="episode_count" id="episode_count" placeholder='Enter episode_count' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("episode_count")}
                onBlur={handleBlur("episode_count")} value={values.episode_count} />
            </div>

            <div className='flex gap-1 md: gap-3 mb-5'>
                <label htmlFor="publish">Publish</label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" name="published" id="published" onChange={handleChange("published")}
                onBlur={handleBlur("published")} value={values.published} checked={values.published}  className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className='flex gap-1 md: gap-3 mb-5'>
                <label htmlFor="latest">Latest</label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" name="latest" id="latest" onChange={handleChange("latest")}
                onBlur={handleBlur("latest")} value={values.latest} checked={values.latest}  className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className='flex gap-1 md: gap-3 mb-5'>
                <label htmlFor="trending">Trending</label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" name="trending" id="trending" onChange={handleChange("trending")}
                onBlur={handleBlur("trending")} value={values.trending} checked={values.trending}  className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className='flex gap-1 md: gap-3 mb-5'>
                <label htmlFor="popular">Popular</label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" name="popular" id="popular" onChange={handleChange("popular")}
                onBlur={handleBlur("popular")} value={values.popular} checked={values.popular}  className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className='flex gap-1 md: gap-3 mb-5'>
                <label htmlFor="recommend">Recommend</label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" name="recommend" id="recommend" onChange={handleChange("recommend")}
                onBlur={handleBlur("recommend")} value={values.recommend} checked={values.recommend}  className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className="flex justify-between">
                  <button type="submit" className='px-3 py-1 text-white font-medium bg-sky-500'>Update</button>
                  <button type="button" className='px-3 py-1 text-white font-medium border border-[#00acc1] text-[#00acc1]' onClick={() => setShowAddSeason(false)}>Cancel</button>
              </div>
          </Form>)}
        </Formik>
        
      </div>
    </div>
  )
}

export default EditSeasonModal