import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/auth/AuthContext";
import { useState } from "react";
import { useMediaQuery } from "../hooks/useMediaQuery";
import SideMenu from "../components/SideMenu";
import Navbar from "../components/Navbar";
import MobileSideMenu from "../components/MobileSideMenu";

const PrivateRoutes = ({ children }) => {
  const { user, pageLoading } = useAuth();
  const [showSidebar, setShowSidebar] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  return !pageLoading ? user ? 
  <main className="flex h-screen w-screen overflow-hidden">

    {!isMobile && <SideMenu />}

    <div className={`${isMobile ? "w-screen" : "w-[calc(100vw-240px)]"}`}>
      <Navbar setShowSidebar={setShowSidebar} />
      {isMobile && showSidebar &&
        <MobileSideMenu setShowSidebar={setShowSidebar} />
      }
      <div className="w-full px-4 md:px-8 mt-[60px] mb-10">
        {children}
      </div>
    </div>
  </main> : 
  <Navigate to="/login" /> : <p>Loading...</p>;
}

export default PrivateRoutes;