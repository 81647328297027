import { IoMdArrowForward } from "react-icons/io";

function Card({ title, value, icon}) {
  return (
    <div className='bg-[#1091FF] px-3 py-3 rounded-sm cursor-pointer'>
        <div className='flex gap-3 mb-5'>
            <div className='w-12 h-12 bg-[#55AFFC] flex justify-center items-center'>
                {icon}
            </div>
            <div>
            <h3 className='font-normal text-gray-300'>{title}</h3>
            <p className='font-medium text-2xl'>{value}</p>
            </div>
        </div>
        

        <div className='flex justify-between text-gray-300'>
            <p className='text-xs'>Since last month</p>
            <div className='text-xs'><IoMdArrowForward /></div>
        </div>
    </div>
  )
}

export default Card