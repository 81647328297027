import { supabase } from "../utils/supabase"

function DeleteGenreModal({setShowModal, genre}) {

    const handleSubmit = async () => {
        const { error } = await supabase
        .from('genres')
        .delete()
        .eq('id', genre.id)


        if (error) {
            alert(error.message)
        }

        setShowModal(false)
    }

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#1C2340] rounded-md w-11/12 md:w-8/12 lg:w-4/12 overflow-y-scroll overflow-x-hidden relative modalContain"
      >
          <h2 className="border-b-[1px] border-gray-500 px-4 py-4 font-medium text-lg text-gray-400">Delete {genre.name}</h2>

          <div className="px-4 py-6 text-sm">
            <p>Are you sure you want to delete this genre, this action is cannot be undone.</p>

            <div className="flex justify-between mt-5">
                <button type="submit" className='px-3 py-1 text-white font-medium bg-sky-500 text-sm rounded-md' onClick={() => handleSubmit()}>Delete</button>
                <button type="button" className='px-3 py-1 text-white font-medium border border-[#00acc1] text-[#00acc1] text-sm rounded-md' onClick={() => setShowModal(false)}>Cancel</button>
            </div>

          </div>
        
      </div>
    </div>
  )
}

export default DeleteGenreModal