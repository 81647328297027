import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth/AuthContext";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { IoMenu } from "react-icons/io5";
import { supabase } from "../utils/supabase";
import { IoIosArrowDown } from "react-icons/io";
import { useState, useRef } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

function Navbar({ setShowSidebar}) {
    const { user } = useAuth();
    const ref = useRef();

    
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [showDropDown, setShowDropDown] = useState(false);
    useOnClickOutside(ref, () => setShowDropDown(false));

  return (
    <nav className={`flex justify-between px-4 md:px-8 h-[60px] items-center bg-[#151935] fixed z-50 ${isMobile ? "left-0" : "left-[240px]"} right-0 top-0`}>
        <div className='flex items-center justify-between w-full'>
            <div className="flex gap-2">
                {isMobile && <IoMenu size={25} onClick={() => setShowSidebar(true)}/>}
                <div className="relative">
                    <button className="text-gray-300 px-2 py-1 border-[1px] border-gray-400 rounded-md text-sm flex gap-1 items-center" onClick={() => setShowDropDown(true)}>
                        Create
                        <IoIosArrowDown />
                    </button>
                    {showDropDown && (
                        <div ref={ref} className="absolute w-[160px] z-10 bg-[#1C2340] flex flex-col left-0 -bottom-[70px] border-[1px] border-gray-500 shadow-md px-3 py-2 rounded-md gap-2 text-sm">
                            <Link to="/add-movies" onClick={() => setShowDropDown(false)}>Movie</Link>
                            <Link to="/add-series" onClick={() => setShowDropDown(false)}>Serie</Link>
                        </div>
                    )}
                    </div>
            </div>

            <div className="flex gap-2 ml-4 items-center text-sm text-gray-300">
                {!isMobile && <p>{user && user.user_metadata && user.user_metadata.name && user.user_metadata.name}</p>}
                <div className="bg-gray-600 rounded-full p-2 border-[1px] border-gray-500 cursor-pointer" onClick={() => supabase.auth.signOut()}>
                    <IoPersonOutline size={20} className="text-gray-300" />
                </div>
            </div>
        </div>
        
    </nav>
  )
}

export default Navbar