import { useEffect, useState } from "react";
import { supabase } from "../utils/supabase";
import { differenceInDays, parseISO } from "date-fns";
import EditSubscriptionModal from "../components/EditSubscription";

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState("");

  function calculateDaysToTargetDate(targetDate) {
    const parsedTargetDate = parseISO(targetDate);
    const currentDate = new Date();
    const daysDifference = differenceInDays(parsedTargetDate, currentDate);

    if (daysDifference > 0) {
      return daysDifference;
    }
    return 0;
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  const getSubscriptions = async () => {
    let allSubscriptions = [];
    let start = 0;
    const batchSize = 1000; // Number of records to fetch in each batch

    while (true) {
      const { data, error } = await supabase
        .from("subscriptions")
        .select("*, profiles(*)") // Fetch subscriptions with associated profiles
        .range(start, start + batchSize - 1); // Fetch a batch of rows

      if (error) {
        console.error("Error fetching subscriptions:", error);
        break;
      }

      if (data && data.length > 0) {
        allSubscriptions = allSubscriptions.concat(data); // Append new batch to all subscriptions
        start += batchSize; // Move to the next batch
      } else {
        break; // Exit loop if no more data
      }
    }

    setSubscriptions(allSubscriptions);
  };

  // Filter subscriptions based on search text and selected filter
  let subs =
    subscriptions &&
    subscriptions.filter(
      (sub) =>
        sub.profiles.email &&
        sub.profiles.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

  subs =
    subs && filterBy !== ""
      ? filterBy === "free"
        ? subs.filter((sub) => sub.free_trial)
        : filterBy === "basic"
        ? subs.filter((sub) => sub.basic)
        : filterBy === "standard"
        ? subs.filter((sub) => sub.standard)
        : subs
      : subs;

  return (
    <div className="w-full h-screen overflow-y-scroll py-5">
      <div className="flex justify-between w-full items-center">
        <div>
          <h1>Subscriptions</h1>
          <p className="text-gray-400">Manage subscriptions</p>
        </div>
      </div>

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden">
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none">
            <caption className="py-3 px-3">
              <section className="flex justify-between items-center w-full gap-4">
                <input
                  type="text"
                  placeholder="search"
                  className="px-3 py-2 rounded-lg placeholder:text-[#bcbfc2] outline outline-1 outline-[#f4f3f7] bg-transparent w-full"
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                />

                <select
                  name=""
                  id=""
                  className="px-3 py-2 ml-2 bg-transparent rounded-lg outline outline-1 outline-[#ededed] text-sm"
                  onChange={(event) => setFilterBy(event.target.value)}
                >
                  <option value="">All Plans</option>
                  <option value="free">Free</option>
                  <option value="basic">Basic Premium</option>
                  <option value="standard">Standard Premium</option>
                </select>
              </section>
            </caption>
            <thead>
              <tr className="border-b-[1px] border-gray-600">
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Email</th>
                <th className="py-2 text-left pl-3">Subscription (days)</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions &&
                subs &&
                subs.map((sub, index) => (
                  <tr
                    key={sub.id}
                    className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30"
                  >
                    <td className="py-2 text-left pl-3">{index + 1}</td>
                    <td className="py-2 text-left pl-3">{sub.profiles.email}</td>
                    <td className="py-2 text-left pl-3">
                      {sub.free_trial ? `Free` : ""}
                      {sub.basic
                        ? `Basic (${calculateDaysToTargetDate(sub.end_date)})`
                        : ""}
                      {sub.standard
                        ? `Standard (${calculateDaysToTargetDate(sub.end_date)})`
                        : ""}
                    </td>
                    <td className="py-2 text-left pl-3">
                      <button
                        className="px-3 py-1 bg-sky-500 text-white text-sm rounded-md"
                        onClick={() => setSelectedIndex(sub)}
                      >
                        Edit
                      </button>
                    </td>
                    {selectedIndex && selectedIndex.id === sub.id && (
                      <EditSubscriptionModal
                        sub={sub}
                        setShowAddSeason={setSelectedIndex}
                      />
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
