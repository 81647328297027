import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email Address is required.")
    .email("Invalid Email."),
  password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Password is required."),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email Address is required.")
    .email("Invalid Email."),
});

export const resetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Password is required."),
  confirm_password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("You need to confirm the password"),
});

export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email Address is required.")
    .email("Invalid Email."),
  first_name: Yup.string()
    .required("First name is required.")
    .min(3, "Too short"),
  last_name: Yup.string()
    .required("Last name is required."),
  phone_number: Yup.string()
    .required("Last name is required."),
  password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Password is required."),
});

export const uploadFileSchema = Yup.object().shape({
  description: Yup.string()
    .required("description is required.")
});

export const editFileSchema = Yup.object().shape({
  filename: Yup.string()
    .required("filename is required"),
  extension: Yup.string()
    .required("extension is required"),
  description: Yup.string()
    .required("description is required.")
});

export const passwordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email Address is required.")
    .email("Invalid Email."),
});

export const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Password is required."),
  confirm_password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Confirm password."),
});

export const editProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required.")
    .min(3, "Too short"),
  last_name: Yup.string()
    .required("Last name is required."),
  phone_number: Yup.string()
    .required("Phone number is required."),
});

export const downloadFileSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Should be at least 8 characters.")
    .required("Password is required."),
});

export const organisationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required.")
    .min(3, "Too short"),
  description: Yup.string()
    .required("Description is required.")
    .min(3, "Too short"),
});


export const AddMovieSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required."),
  vj: Yup.string()
    .required("VJ is required."),
  overview: Yup.string()
    .required("Overview is required."),
  video_link: Yup.string()
    .required("Video link is required."),
});


export const AddSerieSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required."),
  vj: Yup.string()
    .required("VJ is required."),
  overview: Yup.string()
    .required("Overview is required."),
});