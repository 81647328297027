import { useContext, useState, useEffect, createContext } from "react";
import { supabase } from "../supabase";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session && session?.user) {
        setUser(session?.user ?? null);
        setPageLoading(false);
      } else {
        setPageLoading(false);
      }
    })
    setLoading(false);

    // listen for changes to auth
    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session && session?.user) {
            setUser(session?.user ?? null);
      } else {
        setUser(null);
      }
    });

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      // Remove event listeners when the component unmounts
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

    

    return data ? () => data : () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };
  
  const value = {
    user,
    loading,
    pageLoading,
    setLoading,
    setUser,
    isOnline,
  };

  if (loading) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};