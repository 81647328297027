import { useEffect, useState } from "react"
import { adminAuthClient } from "../utils/supabase"
import DeleteUserModal from "../components/DeleteUser"

function Users() {
  const [users, setUsers] = useState(null)
  const [deleteUser, setDeleteUser] = useState(null)

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {

    const { data: { users } } = await adminAuthClient.listUsers()

    if(users){
      setUsers(users)
    }
  }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
          <h1>Users</h1>
          <p className='text-gray-400'>Manage users</p>
        </div>
      </div>

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden">
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none">
            <thead>
              <tr className="border-b-[1px] border-gray-600">
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Name</th>
                <th className="py-2 text-left pl-3">Email</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users && users.map((user, index) => (
                <tr key={user.id} className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                  <td className="py-2 text-left pl-3">{index + 1}</td>
                  <td className="py-2 text-left pl-3">{user.user_metadata && user.user_metadata.name ? user.user_metadata.name : user.user_metadata.username}</td>
                  <td className="py-2 text-left pl-3">{user.email}</td>
                  <td className="py-2 text-left pl-3">
                    <button className='px-3 py-1 bg-sky-500 text-white text-sm rounded-md' onClick={() => setDeleteUser(user)}>Delete</button>
                  </td>
                  {deleteUser && deleteUser.id === user.id && (
                    <DeleteUserModal user={user} setShowModal={setDeleteUser} />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Users