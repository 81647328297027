import { Formik, Form } from "formik"
import { supabase } from "../utils/supabase"
import { useState } from "react"

function EditSubscriptionModal({ setShowAddSeason, sub}) {
    const handleSubmit = async (values, resetForm) => {
      if(values.subscribed === "free") {
        const { error } = await supabase
        .from('subscriptions')
        .update({free_trial: true, basic: false, standard: false, subscribed: false, end_date: values.end_date})
        .eq('id', sub.id)

        if (error) {
            alert(error.message)
        }
      } else if(values.subscribed === "basic") {
        const { error } = await supabase
        .from('subscriptions')
        .update({basic: true, free_trial: false, standard: false, subscribed: true, end_date: values.end_date})
        .eq('id', sub.id)

        if (error) {
            alert(error.message)
        }
      } else if(values.subscribed === "standard") {
        const { error } = await supabase
        .from('subscriptions')
        .update({standard: true, free_trial: false, basic: false, subscribed: true, end_date: values.end_date})
        .eq('id', sub.id)

        if (error) {
            alert(error.message)
        }
      }

        resetForm({
          subscribed: sub.subscribed,
          end_date: ""
        })

        setShowAddSeason(false)
    }

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#1C2340] rounded-md w-11/12 md:w-8/12 lg:w-4/12 overflow-y-scroll overflow-x-hidden relative modalContain"
      >
        <h2 className="border-b-[1px] border-gray-500 px-4 py-4 font-medium text-lg text-gray-400">Edit Subscription</h2>
        <Formik
            initialValues={{
                subscribed: sub.subscribed,
                end_date: ""
              }}
              enableReinitialize
              // validationSchema={loginSchema}
              onSubmit={(values, { resetForm }) => {handleSubmit(values, resetForm)}}
        >
        {({ values, handleChange, handleBlur }) => (
          <Form className="px-4 py-6 text-sm">
            <div className='flex flex-col gap-1 mb-5 text-gray-500'>
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" placeholder='Enter email' className='bg-transparent border border-gray-500 text-gray-500 px-3 py-2 rounded' onChange={handleChange("email")}
                onBlur={handleBlur("email")} value={sub.profiles.email} readOnly />
            </div>

            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="subscribed">Subscription Plan</label>
                <select name="subscribed" id="subscribed" className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("subscribed")} onBlur={handleBlur("subscribed")} value={values.subscribed} required>
                    <option value="">Choose Plan</option>
                    <option value="free">Free</option>
                    <option value="basic">Basic Premium</option>
                    <option value="standard">Standard Premium</option>
                </select>
            </div>


            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="end_date">New End Date</label>
                <input type="date" name="end_date" id="end_date" placeholder='Enter end_date' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("end_date")}
                onBlur={handleBlur("end_date")} value={values.end_date} required />
            </div>

            <div className="flex justify-between mt-5">
                  <button type="submit" className='px-3 py-1 text-white font-medium bg-sky-500 text-sm rounded-md'>Update</button>
                  <button type="button" className='px-3 py-1 text-white font-medium border border-[#00acc1] text-[#00acc1] text-sm rounded-md' onClick={() => setShowAddSeason(false)}>Cancel</button>
              </div>
          </Form>)}
        </Formik>
        
      </div>
    </div>
  )
}

export default EditSubscriptionModal