import { Formik, Form } from "formik"
import { supabase } from "../utils/supabase"

function EpisodeModal({ setShowAddSeason, season}) {

    const handleSubmit = async (values, resetForm) => {
        const { error } = await supabase
        .from('episodes')
        .insert(values)

        if (error) {
            alert(error.message)
        }
        resetForm({ 
            title: "",
            description: "",
            thumbnail_medium: "",
            source: "",
            order: "",
            video_link: "",
            season_id: season,
            status: false,
            published: true
         })

        setShowAddSeason(false)
    }

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#1C2340] rounded-md w-11/12 md:w-9/12 lg:w-6/12 overflow-y-scroll overflow-x-hidden relative modalContain px-4 py-6"
      >
        <Formik
            initialValues={{
                title: "",
                overview: "",
                thumbnail_medium: "",
                source: "",
                order: "",
                video_link: "",
                season_id: season,
                status: false
              }}
              enableReinitialize
              // validationSchema={loginSchema}
              onSubmit={(values, { resetForm }) => {handleSubmit(values, resetForm)}}
        >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="title">Episode Name</label>
                <input type="text" name="title" id="title" placeholder='Enter title' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("title")}
                onBlur={handleBlur("title")} value={values.title} />
            </div>
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="overview">Description</label>
                <textarea type="text" name="overview" id="overview" placeholder='Enter overview' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("overview")}
                onBlur={handleBlur("overview")} value={values.overview} />
            </div>
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="thumbnail_medium">Thumbnail</label>
                <input type="text" name="thumbnail_medium" id="thumbnail_medium" placeholder='Enter thumbnail_medium' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("thumbnail_medium")}
                onBlur={handleBlur("thumbnail_medium")} value={values.thumbnail_medium} />
            </div>

            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="order">Order</label>
                <input type="text" name="order" id="order" placeholder='Enter order' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("order")}
                onBlur={handleBlur("order")} value={values.order} />
            </div>
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="video_link">Video Link</label>
                <input type="text" name="video_link" id="video_link" placeholder='Enter video_link' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("video_link")}
                onBlur={handleBlur("video_link")} value={values.video_link} />
            </div>

            <div className='flex gap-1 mb-5 flex-wrap'>
  <div className='flex gap-1'>
    <label htmlFor="published">Publish</label>
    <label class="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" name="published" id="published" onChange={handleChange("published")}
      onBlur={handleBlur("published")} value={values.published} checked={values.published}  class="sr-only peer" />
      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>
  </div>
  <div className='flex gap-1'>
    <label htmlFor="premium">Premium Only</label>
    <label class="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" name="premium" id="premium" onChange={handleChange("premium")}
      onBlur={handleBlur("premium")} value={values.premium} checked={values.premium}  class="sr-only peer" />
      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>
  </div>
  <div className="flex justify-between">
    <button type="submit" className='px-3 py-1 text-white font-medium bg-sky-500'>Add</button>
    <button type="button" className='px-3 py-1 text-white font-medium border border-[#00acc1] text-[#00acc1]' onClick={() => setShowAddSeason(false)}>Cancel</button>
  </div>
</div>
          </Form>)}
        </Formik>
        
      </div>
    </div>
  )
}

export default EpisodeModal