import { supabase, adminAuthClient } from '../utils/supabase'
import { Helmet } from "react-helmet"
import { Formik, Form, ErrorMessage } from "formik";
import { loginSchema } from "../utils/validators";
import toast from 'react-hot-toast';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../utils/errorMessages";
import { PiEyeSlash, PiEye } from "react-icons/pi"
// import Logo from "../assets/images/Logo3.png"

function DeleteAccount() {

    const [showPassword, togglePassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleDeleteRequest = async (values, resetForm) => {
        try{
            const { data, error } = await supabase.auth.signInWithPassword({
              email: values.email,
              password: values.password
            });
      
            if(data && data.user){
                const { error: deleteUserError } = await supabase.from('subscriptions').delete().eq('id', data.user.id)

                if(deleteUserError){
                } else {
                  const { data:data3, error } = await adminAuthClient.deleteUser(
                    data.user.id
                  )
                  toast.success("Successfully deleted account", { position: "top-right" });
                  
                  if (error) {
                      alert(error.message)
                  }
          
                }

                // const { data: data2, error } = await supabase.auth.admin.deleteUser(
                //     data.user.id
                // )
              
            } else if (error) {
              toast.error(`${errorMessages[error] ? errorMessages[error] : error}`, { position: "top-right" });
            }
            
          } catch(error) {
            toast.error(`${error.message}`, { position: "top-right" });
          }
          resetForm({ email: "", password: "" });
    }
  return (
    <div className='w-screen h-screen flex justify-center items-center overflow-hidden'>
      <Helmet>
        <title>Account Deletion - Kampala Relaxatio</title>
      </Helmet>
      <main className='rounded-md p-8'>
        {/* <img src={Logo} alt="logo" className='w-20 mx-auto mb-5' /> */}
        <h3 className='text-lg font-bold text-center mb-5'>We hate to see you leave</h3>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={loginSchema}
          onSubmit={(values, { resetForm }) => {handleDeleteRequest(values, resetForm)}}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <div className='flex flex-col gap-1 mb-3'>
                <label htmlFor="email">Email</label>
                <input type="email" name="" id="" className='bg-transparent border-[1px] border border-gray-400 rounded-[4px] px-3 py-2 w-[350px]' placeholder='Enter email' onChange={handleChange("email")}
                  onBlur={handleBlur("email")} value={values.email} />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <div className='flex flex-col gap-1 mb-3'>
                <label htmlFor="password">Password</label>
                <div className="relative">
                  <input type={showPassword ? "text" : "password"} name="" id="" className='bg-transparent border-[1px] border border-gray-400 rounded-[4px] px-3 py-2 w-[350px]' placeholder='Enter password' onChange={handleChange("password")}
                    onBlur={handleBlur("password")} value={values.password} />
                  <span
                    className="absolute right-2 top-[25%] cursor-pointer"
                    onClick={() => togglePassword((prev) => !prev)}
                  >
                    {showPassword ? <PiEyeSlash size={18} /> : <PiEye size={18} />}
                  </span>
                </div>
               
                <ErrorMessage name="password">
                  {(msg) => (
                    <div className="text-xs text-red-500 text-left w-full">
                      <p>{msg}</p>
                    </div>
                  )}
                </ErrorMessage>
              </div>

              <input type="submit" className="text-white font-medium bg-[#00acc1] w-full mt-3 px-3 py-2 rounded-[4px] cursor-pointer w-[350px]" value={loading ? "Loading..." : "Delete Account"}/>
            </Form>
          )}
        </Formik>
      </main>

    </div>
  )
}

export default DeleteAccount