import { useState, useEffect } from 'react'
import { supabase } from '../utils/supabase'
import AddSlideModal from '../components/AddSlideModal'
import AddSlide2Modal from '../components/AddSlide2Modal'
import { set } from 'date-fns'
import RemoveSlide from '../components/RemoveSlide'


function SlidePreview() {
    const [slide, setSlide] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [movies, setMovies] = useState(null)
    const [series, setSeries] = useState(null)
    const [deleteSlide, setDeleteSlide] = useState(null)

    useEffect(() => {
        getSlides()
        getMovies()
        getSeries()


        supabase
        .channel("public:preview_slide")
        .on("postgres_changes", { event: "*", schema: "public", table: "preview_slide" }, (payload) => {
          getSlides()
        })
        .subscribe();
    }, [])

    const getSlides = async () => {
        const { data, error } = await supabase
        .from('preview_slide')
        .select()
        .order('order', { ascending: true })

        if(data){
            setSlide(data)
        }
    }
    
    const getMovies = async () => {
        const { data, error } = await supabase
        .from('movies')
        .select('id, title')
        .eq("published", true)
        .order('created_at', { ascending: false })

        if(data){
            setMovies(data)
        }
    }

    // const getSeries = async () => {
    //     const { data, error } = await supabase
    //     .from('series')
    //     .select()
    //     .order('created_at', { ascending: false })
    
    //     if(data){
    //       setSeries(data)
    //     }
    // }


    const getSeries = async () => {
        const { data, error } = await supabase
        .from('seasons')
        .select('*, serie_title: serie_id!inner(title)')
        .order('order', { ascending: true })

        if(data){
            setSeries(data)
        }
    }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
        <div className='flex justify-between w-full items-center'>
        <div>
          <h1>SlidePreview</h1>
          <p className='text-gray-400'>Manage Slides</p>
        </div>

        <div className='flex justify-between items-center gap-2'>
            <button className='px-3 py-2 text-white font-medium bg-sky-500 text-sm rounded-md' onClick={() => setShowModal(true)}>Add Movie</button>
            <button className='px-3 py-2 text-white font-medium bg-sky-500 text-sm rounded-md' onClick={() => setShowModal2(true)}>Add Serie</button>
        </div>
      </div>

      {showModal && <AddSlideModal setShowModal={setShowModal} movies={movies} slide={slide} />}
      {showModal2 && <AddSlide2Modal setShowModal={setShowModal2} series={series} slide={slide} />}

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden">
        <div className="overflow-x-scroll">
            <table className="w-full table-auto md:p-10 select-none">
                <thead>
                    <tr className="border-b-[1px] border-gray-600">
                        <th className="py-2 text-left pl-3">#</th>
                        <th className="py-2 text-left pl-3">Title</th>
                        <th className="py-2 text-left pl-3">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {slide && slide.map((slide, index) => (
                    <tr key={slide.id} className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                        <td className="py-2 text-left pl-3">{index + 1}</td>
                        <td className="py-2 text-left pl-3">{slide.title}</td>
                        <td className="py-2 text-left pl-3 flex gap-1">
                            <button className='px-3 py-1 bg-sky-500 text-white text-sm rounded-md' onClick={() => setDeleteSlide(slide)}
                            >Remove</button>
                        </td>
                        {deleteSlide && deleteSlide.id === slide.id && (
                            <RemoveSlide setShowModal={setDeleteSlide} movie={slide} />
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}

export default SlidePreview