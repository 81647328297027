import { Formik, Form } from "formik"
import { supabase } from "../utils/supabase"

function AddSlide2Modal({setShowModal, series, slide}) {

    const handleSubmit = async (values, resetForm) => {
      if(values.serie_id){
        const selectedSeries = series.filter(value => value.id === +values.serie_id)[0]

        const { error } = await supabase
        .from('preview_slide')
        .insert({...values, title: selectedSeries.serie_title.title})

        if (error) {
            alert(error.message)
        }
        resetForm({ order: "", serie_id: "" })

        setShowModal(false)
      }
    }

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#1C2340] rounded-md w-11/12 md:w-8/12 lg:w-4/12 overflow-y-scroll overflow-x-hidden relative modalContain"
      >
        <h2 className="border-b-[1px] border-gray-500 px-4 py-4 font-medium text-lg text-gray-400">Add Movie</h2>
        <Formik
            initialValues={{
                is_movie: false,
                order: slide.length + 1,
                serie_id: "",
              }}
              enableReinitialize
              // validationSchema={loginSchema}
              onSubmit={(values, { resetForm }) => {handleSubmit(values, resetForm)}}
        >
        {({ values, handleChange, handleBlur }) => (
          <Form className="px-4 py-6 text-sm">
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="name">Select Movie</label>
                <select name="serie_id" id="serie_id" className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("serie_id")} onBlur={handleBlur("serie_id")} value={values.serie_id}>
                    <option value="">Choose Movie</option>
                    {series && series.map((movie, index) => (
                      <option value={movie.id} key={index}>{movie.serie_title.title}</option>
                    ))}
                </select>
            </div>

            <div className="flex justify-between">
                  <button type="submit" className='px-3 py-1 text-white font-medium bg-sky-500 text-sm rounded-md'>Add</button>
                  <button type="button" className='px-3 py-1 text-white font-medium border-[1px] border-[#00acc1] text-[#00acc1] text-sm rounded-md' onClick={() => setShowModal(false)}>Cancel</button>
              </div>
          </Form>)}
        </Formik>
        
      </div>
    </div>
  )
}

export default AddSlide2Modal