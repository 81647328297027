import { useEffect, useState, useRef } from "react";
import { supabase } from "../utils/supabase";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import DeleteMoviesModal from "../components/DeleteMovies";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import toast from 'react-hot-toast';

function Movies() {
  const [movies, setMovies] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const ref = useRef();
  useOnClickOutside(ref, () => setSelectedIndex(null));
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = 100;

  useEffect(() => {
    getMovies(page, pageSize);

    supabase
      .channel("public:movies")
      .on("postgres_changes", { event: "*", schema: "public", table: "movies" }, (payload) => {
        getMovies(page, pageSize);
      })
      .subscribe();
  }, [page]);

  useEffect(() => {
    if (searchText) {
      searchMovies(searchText);
    } else {
      setSearchResults(null);
    }
  }, [searchText]);

  const getMovies = async (page = 1, pageSize = 100) => {
    const { data, error } = await supabase
      .from('movies')
      .select()
      .order('created_at', { ascending: false })
      .range((page - 1) * pageSize, page * pageSize - 1);

    if (data) {
      setMovies(data);
    }
  };

  const searchMovies = async (query) => {
    const { data, error } = await supabase
      .from('movies')
      .select()
      .ilike('title', `%${query}%`);

    if (data) {
      setSearchResults(data);
    }
  };

  const sendPushNotification = (title, thumbnail_medium, overview) => async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const res = await fetch(`https://storage.mlegacytv.com/api/create_notification_with_image/${title}/${thumbnail_medium.replace(/\//g, '$')}/${overview.substring(0, 70)}...`, requestOptions);

    if (res.status === 200) {
      toast.success('Push notification sent successfully', { position: "top-right" });
    }

    setSelectedIndex(null);
  };

  // Adjusted filtering logic
  let filteredMovies = searchResults || movies;
  
  if (filteredMovies) {
    // Apply text search filter
    filteredMovies = filteredMovies.filter((movie) => 
      movie.title && movie.title.toLowerCase().includes(searchText.toLowerCase())
    );

    // Apply filter by publication status
    if (filterBy !== "") {
      filteredMovies = filteredMovies.filter(movie => `${movie.published}` === filterBy);
    }
  }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
          <h1>Movies</h1>
          <p className='text-gray-400'>Movie</p>
        </div>
      </div>

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden">
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none">
            <caption className="py-3 px-3">
              <section className="flex justify-between items-center w-full gap-4">
                <input
                  type="text"
                  placeholder="search"
                  className="px-3 py-2 rounded-lg placeholder:text-[#bcbfc2] outline outline-1 outline-[#f4f3f7] bg-transparent w-full"
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                />

                <select
                  name=""
                  id=""
                  className="px-3 py-2 ml-2 bg-transparent rounded-lg outline outline-1 outline-[#ededed] text-sm"
                  onChange={(event) => setFilterBy(event.target.value)}
                >
                  <option value="">All Movies</option>
                  <option value="true">Published</option>
                  <option value="false">Unpublished</option> {/* Ensure correct value for "Unpublished" */}
                </select>
              </section>
            </caption>
            <thead>
              <tr className="border-b-[1px] border-gray-600">
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Thumbnail</th>
                <th className="py-2 text-left pl-3">Title</th>
                <th className="py-2 text-left pl-3">Description</th>
                <th className="py-2 text-left pl-3">Status</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredMovies && filteredMovies.map((movie, index) => (
                <tr key={movie.id} className="cursor-pointer mb-10 text-gray-300 text-sm border-b-[1px] border-gray-600 hover:bg-gray-600/30">
                  <td className="py-2 text-left pl-3">{index + 1}</td>
                  <td className="py-2 text-left pl-3">
                    <img src={movie.thumbnail_medium} alt="" className='w-20 h-20 object-cover' />
                  </td>
                  <td className="py-2 text-left pl-3">{movie.title}</td>
                  <td className="py-2 text-left pl-3">
                    <p className="line-clamp-3">{movie.overview}</p>
                  </td>
                  <td className="py-2 text-left pl-3">
                    <div className="flex flex-col items-start gap-1">
                      {movie.published ? <span className="text-xs bg-[#00acc1] px-2 py-1 rounded-md text-white">published</span> : <span className="text-xs bg-red-400 px-2 py-1 rounded-md text-white">unpublished</span>}
                      {movie.premium && <span className="text-xs bg-yellow-400 px-2 py-1 rounded-md text-white">premium</span>}
                    </div>
                  </td>
                  <td className="py-2 text-left pl-3 text-sm">
                    <div className="relative">
                      <button className="text-gray-300 px-2 py-1 border-[1px] border-gray-400 rounded-md text-sm flex gap-1 items-center" onClick={() => setSelectedIndex(movie.id)}>
                        Options
                        <IoIosArrowDown />
                      </button>
                      {selectedIndex && selectedIndex === movie.id && (
                        <div className="dropdown-content absolute w-[160px] z-10 bg-[#1C2340] flex flex-col right-0 -bottom-[100px] border-[1px] border-gray-500 shadow-md px-3 py-4 rounded-md gap-2" ref={ref}>
                          <Link to={`/edit/${movie.id}`}>Edit</Link>
                          <div className='py-1 border-b-[1px] border-gray-600' onClick={sendPushNotification(movie.title, movie.thumbnail_medium, movie.overview)}>Send Push Notification</div>
                          <button className='border-[1px] px-2 py-1 rounded-md border-gray-500' onClick={() => setDeleteModal(movie)}>Delete</button>
                        </div>
                      )}
                    </div>
                  </td>
                  {deleteModal && deleteModal.id === movie.id && (
                    <DeleteMoviesModal setShowModal={setDeleteModal} movie={movie} />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mx-1 bg-gray-700 text-white rounded"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span className="px-4 py-2 mx-1 bg-gray-700 text-white rounded">
            Page {page}
          </span>
          <button
            className="px-4 py-2 mx-1 bg-gray-700 text-white rounded"
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Movies;
